import React, {useEffect, useState} from 'react';
import {useAudioRecorder, useAudioRecorderV2} from "../commons/hooks";
import Loader from "./Loader";
import {INTERVIEW_STATUS, RECORDER_STATUS} from "../constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {liveTranscribe, transcribe} from "../store/linguistic/linguisticActions";
import {settings} from "../commons/config";
import {UTILS} from "../commons/utils";

const mimeType = 'audio/wav';

function AudioTranscriber(props) {
  const dispatch = useDispatch();
  const [socketConnected, setSocketConnected] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState()

  const liveTranscribeAudio = useSelector(state => state.liveTranscribedAudio)
  const { transcript } = liveTranscribeAudio;

  const interviewData = useSelector(state => state.retrieveInterview);
  const {loading, interview, error} = interviewData;

  let transcriptConfig = {
    chunkSize: 2000,
    chunkHandler: (chunk, allChunks) => {
      if(allChunks[0] !== chunk) { // Get the headers from the first chunk, without headers the wav chunk is invalid.
        let first = allChunks[0];
        chunk = new Blob([first.slice(0, 44), chunk])
      }

      const audioFile = new File([chunk], `audio-${Date.now()}.wav`, { type: mimeType });

      if(settings.liveTranscript.websocket) {
        UTILS.fileToBase64(audioFile).then(data => {
          // console.log(data)
          dispatch({
            type: 'socket/send',
            payload: data
          })
        })
      }
      else {
        const data = new FormData();
        data.append('id', 'interview');
        data.append('condition_on_previous_text', false);
        data.append('prefix', transcript ? transcript.text : "");
        // data.append('beam_size', 3);
        data.append('file', audioFile);

        dispatch(liveTranscribe(data));
      }
    }
  }

  let {
    permissions,
    recorderStatus,
    audio,
    startRecording,
    stopRecording
  } = useAudioRecorderV2( {
    chunkSize: settings.liveTranscript.enabled ? transcriptConfig.chunkSize : null,
    chunkHandler: settings.liveTranscript.enabled ? transcriptConfig.chunkHandler : null
  });

  // console.log('recording status -> ', recorderStatus);
  useEffect(() => {
    if(settings.liveTranscript.websocket) {
      if (recorderStatus === RECORDER_STATUS.RECORDING) {
        dispatch({
          type: 'socket/connect'
        })
      } else if (recorderStatus === RECORDER_STATUS.INACTIVE) {
        console.log('disconnecting socket');
        dispatch({
          type: 'socket/disconnect'
        })
      }
    }
  }, [recorderStatus]);

  useEffect(() => {
    // TODO: save transcription settings in backend
    if(settings.fullTranscript.enabled && audio) {
      let data = new FormData();
      data.append('file', new File([audio], `${Date.now()}.wav`, { type: mimeType }));
      data.append('id', `interviews/${interview.id}`);
      data.append('response_format', 'json');

      dispatch(transcribe(data));
      setStartTimestamp(null);
    }

    if(settings.e2ePipeline.enabled) {
      props.onAudioHandler(audio);
    }
  }, [audio]);

  useEffect(() => {
    if(transcript && transcript.text && transcript.text.includes('[BLANK_AUDIO]')) {
      // console.log('calling stop recording from live transcript change')
      // console.log(transcript)
      stopRecording();
      props.onStopHandler();
    }
  }, [transcript]);

  useEffect(() => {
    if(props.listen && settings.listen.auto) {
      setStartTimestamp(Date.now());
      startRecording();
    }
  }, [props.listen]);


  return (
    <div className='flex flex-row w-full'>
      {
        !permissions ? (
          <button className='flex flex-row bg-!grey-400/30 hover:bg-!grey-400/50 rounded-md py-2 px-4'>
            <Loader />
          </button>
        ) : recorderStatus === RECORDER_STATUS.INACTIVE ? (
          <button className='flex flex-row bg-!grey-400/30 hover:bg-!grey-400/50 rounded-md py-2 px-4 gap-x-2'
                  disabled={props.disabled}
                  onClick={() => {
                    setStartTimestamp(Date.now());
                    startRecording();
                  }}
          >
            <span className='flex flex-col m-auto'>
              <img className='h-[1rem] m-auto' src='/media/images/icons/mic.webp' alt='' />
            </span>
            <span className='flex flex-col'>Start</span>
          </button>
        ) : (
          <button className='flex flex-row bg-!grey-400/30 hover:bg-!grey-400/50 rounded-md py-2 px-4 gap-x-2'
                  disabled={props.disabled}
                  onClick={() => {
                    stopRecording();
                    props.onStopHandler();
                  }}
          >
            <span className='flex flex-col m-auto'>
              <img className='h-[1rem] m-auto border-[2px] border-!red-600 rounded-full' src='/media/images/icons/stop-2.webp' alt='' />
            </span>
            <span className='flex flex-col'>Stop</span>
          </button>
        )
      }
    </div>
  );
}

export default AudioTranscriber;
